@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    html {
      font-family: "Rubik", system-ui, sans-serif;
    }
    html.lighthouse {
      font-family: "Poppins", system-ui, sans-serif;
    }
    html.hotdogStand {
        font-family: "Comic Sans MS", system-ui, sans-serif;
    }
    html.hack {
        font-family: "Ubuntu Mono", system-ui, sans-serif;
    }
    html.soundShapes {
        font-family: "Sora", system-ui, sans-serif;
    }
    html.chicago {
        font-family: "Tahoma", system-ui, sans-serif;
        text-rendering: optimizeSpeed;
        font-smooth: never !important;
        -webkit-font-smoothing: none !important;
    }
    html.vgui {
        font-family: "Verdana", system-ui, sans-serif;
    }
}

.masonry-grid-item {
  width: calc(100% - 10px);
  margin: 5px;
}

@media (min-width: 768px) {
  .masonry-grid-item {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1124px) {
  .masonry-grid-item {
    width: calc(33.333% - 10px);
  }
}
